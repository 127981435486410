import React, { useEffect, useRef, useState } from 'react';
import { PageProps } from 'gatsby';
import { IExercise, PageContext } from '@appTypes';
import styled from '@emotion/styled';
import SEO from '../site/components/SEO';
import ReactPlayer from 'react-player';
import { theme } from '../site/styles';
import axios from 'axios';
import CustomText from '../site/components/CustomText';
import PlayIcon from '@assets/images/play-icon.svg';

import Box from '@components/Box';
import { MYSTIC, WHITE } from '@constants';
import UserIcon from '@assets/icons/UserIcon';
import { isBrowser } from '@utils';

const { breakpoints, colors } = theme;

const ExercisePage: React.FC<PageProps<{}, PageContext>> = ({ pageContext: { locale }, location }) => {
  const [exerciseData, setExerciseData] = useState<IExercise | null>(null);
  const [exercisePreviewPlaying, setExercisePreviewPlaying] = useState<boolean>(false);

  const videoRef = useRef<ReactPlayer>(null);

  useEffect(() => {
    if (isBrowser()) {
      const urlParams = new URLSearchParams(window.location.search);
      const exerciseId = urlParams.get('exerciseId');
      if (exerciseId) {
        getExercise(exerciseId);
      } else {
        window.location.href = '/';
      }
    }
  }, []);

  const getExercise = async (exerciseId: string) => {
    try {
      const { data }: { data: any } = await axios.get(`${process.env.GATSBY_CONTENT_API_URL}/exercises/${exerciseId}`, {
        headers: {
          'X-Api-Key': process.env.GATSBY_CONTENT_API_KEY,
        },
      });
      if (Object.keys(data).length) {
        setExerciseData(data.data);
      } else {
        navigateMain();
      }
    } catch (error) {
      navigateMain();
    }
  };

  const navigateMain = () => {
    if (isBrowser()) {
      window.location.href = '/';
    }
  };

  return (
    <>
      <Container>
        <SEO
          title={`Hyperhuman | Exercise`}
          description=''
          pathname={location.pathname}
          name={''}
          url={'https://hyperhuman.cc/exercise'}
          locale={locale}
        />
        <Content>
          {exerciseData && (
            <>
              <ExerciseVideo
                onClick={() => {
                  setExercisePreviewPlaying(!exercisePreviewPlaying);
                }}
              >
                {!exercisePreviewPlaying && <ExercisePlayIcon src={PlayIcon} />}
                <ReactPlayer
                  ref={videoRef}
                  width={'100%'}
                  height={'100%'}
                  playing={exercisePreviewPlaying}
                  config={{
                    file: {
                      attributes: {
                        controlsList: 'nodownload',
                        onContextMenu: (e: React.MouseEvent<HTMLVideoElement, MouseEvent>) => {
                          e.preventDefault();
                          e.stopPropagation();
                          return false;
                        },
                      },
                    },
                  }}
                  loop={true}
                  muted={false}
                  url={exerciseData.preview.url}
                />
              </ExerciseVideo>
              <ExerciseMetaWrapper>
                <ExerciseMetaHeader>
                  <CustomText fontSize={28} lineHeight={'42px'} fontWeight={700} fontFamily={'Poppins'}>
                    {exerciseData.name}
                  </CustomText>
                </ExerciseMetaHeader>
                <Box display={'flex'} alignItems={'center'} my={'8px'}>
                  <Box
                    mr={'8px'}
                    borderRadius={'44px'}
                    backgroundColor={MYSTIC}
                    width={'44px'}
                    height={'44px'}
                    display='flex'
                    justifyContent={'center'}
                    alignItems={'center'}
                  >
                    {exerciseData.organization.logo?.url ? (
                      <OrganizationImage src={exerciseData.organization.logo?.url} />
                    ) : (
                      <UserIcon />
                    )}
                  </Box>
                  <div>
                    <CustomText fontSize={14} lineHeight={'18px'} fontWeight={400}>
                      {exerciseData.organization.name}
                    </CustomText>
                    <CustomText fontSize={14} lineHeight={'18px'} color={colors.grannySmith} fontWeight={400}>
                      by {exerciseData.trainer.name}
                    </CustomText>
                  </div>
                </Box>
                <CustomText fontSize={16} lineHeight={'23px'} fontWeight={400}>
                  {exerciseData.muscleGroups?.map((muscleGroup) => muscleGroup.name).join(', ')}
                </CustomText>
                <CustomText fontSize={16} lineHeight={'23px'} fontWeight={400} color={colors.grannySmith}>
                  {exerciseData.equipment?.map((equipment) => equipment.name).join(', ')}
                </CustomText>
              </ExerciseMetaWrapper>
            </>
          )}
        </Content>
      </Container>
    </>
  );
};
export default ExercisePage;

const ExerciseMetaHeader = styled.div`
  flex-direction: column;
  display: flex;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

const OrganizationImage = styled.img`
  border-radius: 44px;
  height: 44px;
  object-fit: cover;
  width: 44px;
`;

const ExerciseMetaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    margin-top: 16px;
  }
`;

const ExercisePlayIcon = styled.img`
  position: absolute;
  width: 37.33px;
  height: 48px;
`;

const Content = styled.div`
  transition: all 150ms ease-in;
`;

const ExerciseVideo = styled.div`
  cursor: pointer;
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin-top: 24px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  video {
    border-radius: 26px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    margin: 94px 0px 24px 0px;
    padding: 0px;
  }
`;

const Container = styled.div`
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    max-width: 1077px;
    margin: 0 auto;
    padding: 0 24px;
  }
  margin: 0px 24px 94px 24px;
  background-color: ${WHITE};
`;
