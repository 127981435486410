import React from 'react';
import Helmet from 'react-helmet';
import Facebook from './Facebook';
import Twitter from './Twitter';

type Props = {
  title?: string;
  description?: string;
  image?: string;
  url?: string;
  name: string;
  pathname?: string;
  locale: string | undefined;
  favIcon?: string;
  noFollow?: boolean;
};

const SEO: React.FC<Props> = ({ title, description, image, url, name, pathname, locale, noFollow }) => {
  return (
    <>
      <Helmet title={title}>
        <meta name='description' content={description} />
        <meta name='image' content={image} />
        {noFollow && <meta name='robots' content={'noindex, nofollow'} />}
        <link rel='canonical' href={`${process.env.GATSBY_URL}${pathname}`} />
        <link rel='alternate' hrefLang='en-us' href={`${process.env.GATSBY_URL}${pathname}`} />
        <link rel='alternate' hrefLang='en-gb' href={`${process.env.GATSBY_URL}${pathname}`} />
        <link rel='alternate' hrefLang='en-au' href={`${process.env.GATSBY_URL}${pathname}`} />
        <link rel='alternate' hrefLang='en-nz' href={`${process.env.GATSBY_URL}${pathname}`} />
      </Helmet>
      <Facebook desc={description} image={image} title={title} type='website' url={url} name={name} locale={locale} />
      <Twitter title={title} image={image} desc={description} />
    </>
  );
};

export default SEO;
